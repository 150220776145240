import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Filter: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#fff',
  ...rest
}) => (
  <svg
    width={24}
    height={24}
    className="m-auto"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.94 22.65c-.48 0-.95-.12-1.39-.36a2.699 2.699 0 0 1-1.41-2.38v-5.3c0-.5-.33-1.25-.64-1.63L3.76 9.02c-.63-.63-1.11-1.71-1.11-2.52V4.2c0-1.6 1.21-2.85 2.75-2.85h13.2c1.52 0 2.75 1.23 2.75 2.75v2.2c0 1.05-.63 2.24-1.22 2.83l-4.33 3.83c-.42.35-.75 1.12-.75 1.74V19c0 .89-.56 1.92-1.26 2.34l-1.38.89c-.45.28-.96.42-1.47.42ZM5.4 2.85c-.7 0-1.25.59-1.25 1.35v2.3c0 .37.3 1.09.68 1.47l3.81 4.01c.51.63 1.01 1.68 1.01 2.62v5.3c0 .65.45.97.64 1.07.42.23.93.23 1.32-.01l1.39-.89c.28-.17.56-.71.56-1.07v-4.3c0-1.07.52-2.25 1.27-2.88l4.28-3.79c.34-.34.75-1.15.75-1.74V4.1c0-.69-.56-1.25-1.25-1.25H5.4Z"
      fill={color}
    />
    <path
      d="M6 10.75a.83.83 0 0 1-.4-.11.76.76 0 0 1-.24-1.04l4.93-7.9a.747.747 0 1 1 1.27.79l-4.93 7.9c-.14.23-.38.36-.63.36Z"
      fill={color}
    />
  </svg>
);

export default Filter;
