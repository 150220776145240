import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Php: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 21,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size + 17}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M19 .512c-10.492 0-19 4.47-19 9.991 0 5.522 8.508 9.994 19 9.994s19-4.472 19-9.994C38 4.981 29.492.512 19 .512Zm0 .997c10.177 0 18.002 4.286 18.002 8.994 0 4.709-7.831 8.997-18.002 8.997C8.823 19.5.997 15.212.997 10.503.997 5.795 8.83 1.51 19 1.51ZM16.693 3.49l-1.922 9.904h2.144l1.107-5.677c1.744 0 2.466-.068 2.783.272.329.353.16.74-.745 5.404h2.174c.94-4.822 1.356-5.845.486-6.645-.827-.763-2.542-.629-4.393-.629l.51-2.629h-2.144ZM7.704 6.126l-1.92 9.911H7.94l.51-2.633c2.413 0 3.913.176 5.3-1.122 1.532-1.41 1.932-3.919.839-5.174-.57-.658-1.488-.982-2.732-.982H7.703h.002Zm16.854 0-1.925 9.911h2.16l.511-2.633c2.538 0 3.943.148 5.3-1.122 1.532-1.41 1.934-3.919.84-5.174-.57-.658-1.488-.982-2.733-.982h-4.153ZM11.086 7.688c1.348.038 2.119.323 1.794 2.015-.465 2.38-2.102 2.134-4.117 2.134l.805-4.147c.558 0 1.068-.015 1.518-.002Zm16.855 0c1.35.038 2.112.323 1.786 2.015-.47 2.414-2.156 2.134-4.117 2.134l.805-4.147c.561 0 1.077-.015 1.526-.002Z"
      fill={color}
    />
  </svg>
);

export default Php;
