import React from 'react';
import Button from 'components/Button';

const FallBack: React.FC<any> = ({ error }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div
      className="flex items-center justify-center text-center mt-60"
      data-testid="fall-back-banner"
    >
      <div>
        <h1 className="text-xl font-bold">Something went wrong</h1>
        <div className="text-text-light text my-4">please try again later</div>
        <Button
          label="Refresh page"
          className="w-40"
          dataTestId="fall-back"
          onClick={handleReload}
        />
      </div>
    </div>
  );
};

export default FallBack;
