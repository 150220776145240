import React from 'react';
import clx from 'classnames';

export enum Variant {
  Screen = 'screen',
  Section = 'section',
  Overlay = 'overlay',
}

type AppProps = {
  variant?: Variant;
};

const Loader: React.FC<AppProps> = ({ variant = Variant.Screen }) => {
  return (
    <div
      className={clx('flex flex-col items-center justify-center', {
        'h-screen': variant === Variant.Screen,
        'h-full': variant === Variant.Section,
        'fixed top-0 bottom-0 right-0 left-0 z-50 bg-white bg-opacity-50':
          variant === Variant.Overlay,
      })}
    >
      <div>
        <img src={require('images/logo-loader.png')} alt="" />
      </div>
      <div className="h-3 bg-indigo-200 w-40 rounded-md mt-4 relative">
        <div className="h-3 bg-primary rounded-md absolute inc"></div>
      </div>
    </div>
  );
};

export default Loader;
