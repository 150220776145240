import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const PasswordShow: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 20,
  color = '#666',
  ...rest
}) => (
  <svg
    id="password-show_svg__Icons"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    x={0}
    y={0}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...rest}
  >
    <style>
      {
        '.password-show_svg__st1{fill:none;stroke:#000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10}'
      }
    </style>
    <path
      className="password-show_svg__st1"
      d="M29 16s-5.8 8-13 8-13-8-13-8 5.8-8 13-8 13 8 13 8z"
    />
    <circle className="password-show_svg__st1" cx={16} cy={16} r={4} />
  </svg>
);

export default PasswordShow;
