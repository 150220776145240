import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const SvgAzure: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 28,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.857 10.539 5 20.23h3.857L14 5l-5.143 5.539ZM23 23 16.571 7.77 14 14.691l3.857 5.539L10.143 23H23Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgAzure;
