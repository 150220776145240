import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Reading: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 36,
  color = '#000',
  ...rest
}) => (
  <svg
    height={size}
    width={size}
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    {...rest}
  >
    <g>
      <path
        fill={color}
        d="M430.135,14.339h0.017c0-0.008,0-0.008,0-0.008L430.135,14.339z"
      />
      <path
        fill={color}
        d="M508.672,96.445c-2.126-2.118-5.059-3.327-8.06-3.327c-17.409,0-34.202,2.092-50.238,4.983
		c0.034-23.387,0.085-68.453,0.085-70.589c0-2.015-0.411-4.254-1.406-6.269c-0.721-1.509-1.715-2.881-2.83-3.979
		c-1.698-1.638-3.585-2.649-5.3-3.259c-1.767-0.592-3.396-0.832-4.992-0.832c-1.852,0.018-3.67,0.274-5.78,1.166
		c-19.45,8.302-39.312,20.205-58.436,34.407c-28.678,21.303-55.64,47.785-76.258,75.451c-10.308,13.841-19.038,28.001-25.539,42.074
		c-4.854,10.54-8.49,21.046-10.497,31.405h-6.895c-2.916-14.982-9.125-30.256-17.649-45.496
		c-15.831-28.146-39.638-56.223-66.635-80.554c-27.049-24.304-57.236-44.818-86.394-57.296c-2.092-0.884-3.91-1.14-5.762-1.158
		c-1.424,0-2.865,0.18-4.408,0.643c-1.149,0.352-2.35,0.858-3.55,1.612c-1.784,1.115-3.585,2.856-4.802,5.078
		c-1.236,2.195-1.784,4.742-1.767,7.006c0,2.136,0.051,47.202,0.085,70.589c-16.054-2.89-32.846-4.983-50.254-4.983
		c-2.985,0-5.935,1.209-8.044,3.327C1.218,98.572,0,101.496,0,104.489V384.63c0,5.669,4.168,10.472,9.794,11.269
		c14.836,2.11,32.314,6.046,50.718,11.638c27.632,8.371,57.356,20.454,83.752,35.333c13.19,7.427,25.54,15.556,36.379,24.21
		c10.858,8.654,20.171,17.838,27.375,27.272c2.144,2.813,5.523,4.476,9.056,4.476h77.87c3.533,0,6.912-1.663,9.056-4.476
		c9.588-12.59,22.966-24.699,38.678-35.737c23.567-16.594,52.262-30.77,80.682-41.507c28.42-10.738,56.601-18.053,78.864-21.209
		c5.608-0.797,9.776-5.6,9.776-11.269V104.489C512,101.496,510.783,98.572,508.672,96.445z M209.099,461.737
		c-8.079-7.718-17.032-14.974-26.671-21.74c-25.659-18.052-55.864-32.888-85.742-44.183c-25.968-9.811-51.627-16.903-73.907-20.874
		V116.238c16.208,0.892,32.245,3.542,47.956,6.81c0.788,0.171,1.595,0.24,2.419,0.232l-0.103,0.488
		c32.846,6.836,62.656,19.091,86.6,35.05c22.246,14.81,39.329,32.777,49.449,52.382V461.737z M168.483,145.559
		c-23.824-15.883-52.571-28.086-84.062-35.573c0-2.136,0-5.96-0.018-10.882c-0.017-15.789-0.051-42.528-0.068-58.497
		c13.928,6.981,28.352,15.978,42.382,26.414c26.912,19.973,52.519,45.152,71.575,70.777c9.536,12.795,17.443,25.71,23.12,38.008
		c4.682,10.146,7.804,19.854,9.244,28.695h-7.066C211.962,181.681,192.702,161.699,168.483,145.559z M286.968,476.067h-61.935
		v-255.63h17.666c0.018,0,0.018,0,0.018,0h26.568c0.016,0,0.016,0,0.034,0h17.649V476.067z M288.426,204.501h-7.101
		c2.023-12.4,7.427-26.551,15.66-41.173c14.184-25.273,36.551-51.876,62.021-74.773c21.766-19.613,45.847-36.516,68.676-47.965
		c-0.018,8.198-0.034,19.218-0.052,30.17c-0.016,16.989-0.034,33.729-0.051,39.226c-31.474,7.487-60.221,19.69-84.062,35.573
		C319.299,161.699,300.055,181.681,288.426,204.501z M489.24,374.948c-13.739,2.444-28.747,6.072-44.355,10.806
		c-29.09,8.816-60.169,21.414-88.316,37.271c-14.064,7.941-27.409,16.689-39.415,26.259c-5.008,3.996-9.759,8.139-14.235,12.427
		V211.199c10.119-19.604,27.202-37.571,49.448-52.382c23.927-15.96,53.737-28.215,86.6-35.05l-0.103-0.488
		c0.806,0.009,1.63-0.06,2.418-0.232c15.712-3.268,31.748-5.917,47.957-6.81V374.948z"
      />
    </g>
  </svg>
);

export default Reading;
