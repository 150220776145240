import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Cube: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 36,
  color = '#000',
  ...rest
}) => (
  <svg
    fill={'none'}
    height={size}
    width={size}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 226.153 226.153"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    // enable-background="new 0 0 226.153 226.153"
    {...rest}
  >
    <g>
      <path
        d="m202.103,51.666c-0.057-0.031-0.117-0.052-0.174-0.081-0.11-0.074-0.218-0.15-0.333-0.218l-86.542-50.548c-1.923-1.123-4.31-1.089-6.2,0.089l-84.485,52.648c-0.094,0.058-0.179,0.124-0.269,0.187-0.022,0.012-0.045,0.019-0.067,0.031-1.906,1.058-3.088,3.066-3.088,5.246v108.122c0,2.072 1.069,3.997 2.827,5.092l85.082,53.012c0.971,0.605 2.072,0.908 3.173,0.908 1.114,0 2.227-0.31 3.206-0.928l87.18-55.11c1.739-1.1 2.794-3.014 2.794-5.072v-108.122c-2.84217e-14-2.187-1.189-4.2-3.104-5.256zm-101.264,60.464l-67.894,43.976v-86.279l67.894,42.303zm17.189,10.572l69.794,42.44-69.794,44.12v-86.56zm5.385-10.769l69.794-44.12v86.56l-69.794-42.44zm63.503-55.242l.741,.433-75.653,47.824-73.713-45.929 .824-.514 73.012-45.498 74.789,43.684zm-148.782,110.352l67.894-43.977v86.279l-67.894-42.302z"
        fill={color}
      />
    </g>
  </svg>
);

export default Cube;
