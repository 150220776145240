import apiService from '../utils/apiService';

export const getCurrentUser = async () => {
  const { data } = await apiService.get('/v1/users/me');
  return data;
};

export const registerAccount = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/v1/auth/register', payload);
  return data;
};

export const resendVerificationEmail = async (email: string) => {
  return apiService.post('/v1/auth/resend-verification-email', { email });
};
