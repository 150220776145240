import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Google: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 20,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size + 6}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.85 7.923C4.32 4.231 7.89 1 12.78 1c6.11 0 8.719 6.715 8.93 6.923.212.208 3.29.923 3.29 6.462C24.843 15.923 23.59 19 19.83 19H5.73c-1.724 0-5.077-1.108-4.7-5.539.375-4.43 3.603-5.538 5.17-5.538.94 0 3.29.462 5.17 2.308"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Google;
