import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const DotNet: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 35,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#cib_dot-net_svg__a)">
      <path
        d="M4.62 11.035v12.76h1.493v-9.228a13.76 13.76 0 0 0-.074-1.749h.056c.109.279.245.547.405.797l6.563 10.174h1.828V11.035h-1.486v8.972c-.018.627.017 1.254.09 1.875h-.033c-.164-.29-.337-.575-.519-.854l-6.386-9.992H4.62Zm13.581 0v12.76h6.784l.006-1.4h-5.264v-4.427h4.609v-1.344h-4.61v-4.238h4.946v-1.35H18.2Zm7.93 0v1.35h3.668v11.41h1.493v-11.41h3.709v-1.35h-8.87ZM.95 21.973a.941.941 0 0 0-.666.302.995.995 0 0 0-.284.7.985.985 0 0 0 1.687.702.983.983 0 0 0 .296-.701.983.983 0 0 0-.296-.701.959.959 0 0 0-.702-.302H.951Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="cib_dot-net_svg__a">
        <path fill="#fff" d="M0 0h35v35H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default DotNet;
