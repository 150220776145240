import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const InvoiceInfo: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 23,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M20.125 6.708v9.584c0 2.875-1.438 4.791-4.792 4.791H7.667c-3.354 0-4.792-1.916-4.792-4.791V6.708c0-2.875 1.438-4.791 4.792-4.791h7.666c3.354 0 4.792 1.916 4.792 4.791Z"
      stroke="#0B0F32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.896 4.313v1.916c0 1.054.862 1.917 1.916 1.917h1.917M7.667 12.458H11.5M7.667 16.292h7.667"
      stroke="#0B0F32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InvoiceInfo;
