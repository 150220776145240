import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
    size?: number;
    color?: string;
};

const ArrowLeft: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
    size = 16,
    color = '#000000',
    ...rest
}) => (
    <svg
        width={size}
        height={size}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path d="M7.69408 13.6918C7.88996 13.4945 8 13.2268 8 12.9477C8 12.6686 7.88996 12.401 7.69408 12.2036L2.52205 6.99365L7.69408 1.78371C7.88441 1.5852 7.98973 1.31933 7.98735 1.04337C7.98496 0.767401 7.87508 0.503418 7.68135 0.308273C7.48763 0.113129 7.22557 0.00243814 6.95161 4.00606e-05C6.67765 -0.00235802 6.41372 0.103729 6.21666 0.295453L0.30592 6.24952C0.11004 6.44689 5.65843e-07 6.71456 5.53644e-07 6.99365C5.41444e-07 7.27273 0.11004 7.5404 0.30592 7.73777L6.21666 13.6918C6.4126 13.8892 6.67831 14 6.95537 14C7.23243 14 7.49814 13.8892 7.69408 13.6918V13.6918Z"
            fill={color}
            stroke={color}
            width={size}
            height={size}
        />
    </svg>

);

export default ArrowLeft;