import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from './context/AuthContext';
import toast, { ToastBar, Toaster, useToasterStore } from 'react-hot-toast';
import Icon from 'components/Icon';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  RequireAuth,
  RequireAccountAdmin,
  RequireSuperAdmin,
} from 'components/RequireAuth';
import { ErrorBoundary } from 'react-error-boundary';
import FallBack from 'components/FallBack';
import Loader from 'components/Loader';
import PageNotFound from 'pages/PageNotFound';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const Register = lazy(() => import('./pages/Register'));
const Login = lazy(() => import('./pages/Login'));
const LoginRequired = lazy(() => import('./pages/LoginRequired'));
const Onboarding = lazy(() => import('./pages/Onboarding'));
const Deployments = lazy(() => import('./pages/Deployments'));
const CreateDeployment = lazy(() => import('./pages/CreateDeployment'));
const DeploymentDetail = lazy(() => import('./pages/DeploymentDetail'));
const Settings = lazy(() => import('./pages/Setting'));
const ProjectInfo = lazy(() => import('./pages/ProjectInfo'));
const Manage = lazy(() => import('./pages/Manage'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Spaces = lazy(() => import('./pages/Spaces'));
const ProductCallback = lazy(() => import('./pages/Login/ProductCallback'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

function App() {
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 3)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_AUTH_AUD}
          scope="read:current_user update:current_user_metadata"
          cacheLocation="localstorage"
          useRefreshTokens
        >
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={FallBack}>
              <AuthProvider>
                <Elements stripe={stripePromise}>
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route path="login" element={<Login />} />
                      <Route path="register" element={<Register />} />
                      <Route
                        path="login-required"
                        element={<LoginRequired />}
                      />
                      <Route path="/" element={<RequireAuth />}>
                        <Route index element={<Dashboard />} />
                      </Route>
                      <Route path="onboarding" element={<RequireAuth />}>
                        <Route index element={<Onboarding />} />
                      </Route>
                      <Route path="project-info" element={<RequireAuth />}>
                        <Route index element={<ProjectInfo />} />
                      </Route>
                      <Route path="/manage" element={<RequireSuperAdmin />}>
                        <Route index element={<Manage />} />
                      </Route>
                      <Route
                        path="deployments"
                        element={<RequireAccountAdmin />}
                      >
                        <Route index element={<Deployments />} />
                        <Route path="create" element={<CreateDeployment />} />
                      </Route>
                      <Route path="deployments/:id" element={<RequireAuth />}>
                        <Route index element={<DeploymentDetail />} />
                      </Route>
                      <Route path="accounts" element={<RequireSuperAdmin />}>
                        <Route
                          path=":id/deployments"
                          element={<Deployments />}
                        />
                      </Route>
                      <Route path="/settings" element={<RequireAuth />}>
                        <Route index element={<Settings />} />
                      </Route>
                      <Route path="/spaces" element={<RequireAuth />}>
                        <Route index element={<Spaces />} />
                      </Route>
                      <Route path="/product-callback" element={<ProductCallback />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </Suspense>
                </Elements>
              </AuthProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </Auth0Provider>
      </QueryClientProvider>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: '#51b06c',
            },
          },
          error: {
            style: {
              background: '#dd6d6d',
            },
          },
        }}
      >
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              borderRadius: '4px',
              color: '#fff',
              padding: '16px',
              display: 'flex',
              rowGap: '12px',
            }}
          >
            {({ message }) => (
              <div className="flex space-x-2 items-center justify-evenly">
                {t.type === 'error' && (
                  <div>
                    <Icon name="exclamation" size={20} />
                  </div>
                )}
                {t.type === 'success' && (
                  <div>
                    <Icon name="check" color="#fff" size={20} />
                  </div>
                )}
                {message}
                {t.type !== 'loading' && (
                  <div onClick={() => toast.dismiss(t.id)}>
                    <Icon name="x" size={20} />
                  </div>
                )}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
}

export default App;
