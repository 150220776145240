import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Exclamation: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#fff',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0  ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >

    <path d="M10.0003 1.66675C14.6028 1.66675 18.3337 5.39841 18.3337 10.0001C18.3337 14.6017 14.6028 18.3334 10.0003 18.3334C5.39783 18.3334 1.66699 14.6017 1.66699 10.0001C1.66699 5.39841 5.39783 1.66675 10.0003 1.66675ZM10.0003 3.05591C6.17116 3.05591 3.05616 6.17091 3.05616 10.0001C3.05616 13.8292 6.17116 16.9442 10.0003 16.9442C13.8295 16.9442 16.9445 13.8292 16.9445 10.0001C16.9445 6.17091 13.8295 3.05591 10.0003 3.05591V3.05591ZM9.99949 12.0851C10.2203 12.0851 10.432 12.1728 10.5882 12.3289C10.7443 12.485 10.832 12.6968 10.832 12.9176C10.832 13.1384 10.7443 13.3501 10.5882 13.5062C10.432 13.6624 10.2203 13.7501 9.99949 13.7501C9.7787 13.7501 9.56695 13.6624 9.41082 13.5062C9.2547 13.3501 9.16699 13.1384 9.16699 12.9176C9.16699 12.6968 9.2547 12.485 9.41082 12.3289C9.56695 12.1728 9.7787 12.0851 9.99949 12.0851V12.0851ZM9.99532 5.83341C10.1465 5.83322 10.2926 5.88782 10.4066 5.98711C10.5206 6.0864 10.5948 6.22364 10.6153 6.37341L10.6212 6.45758L10.6245 10.2092C10.6247 10.3677 10.5646 10.5202 10.4566 10.6361C10.3486 10.752 10.2006 10.8225 10.0425 10.8334C9.88446 10.8443 9.72816 10.7948 9.60524 10.6949C9.48231 10.5949 9.40192 10.452 9.38032 10.2951L9.37449 10.2101L9.37116 6.45925C9.37105 6.3771 9.38713 6.29574 9.41849 6.21981C9.44985 6.14389 9.49587 6.07489 9.55392 6.01677C9.61197 5.95864 9.6809 5.91253 9.75679 5.88107C9.83267 5.84961 9.91401 5.83341 9.99616 5.83341H9.99532Z"
      fill={color}
    />
  </svg>
);

export default Exclamation;
