import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Invoice: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 23,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M8.625 21.083h5.75c4.792 0 6.709-1.916 6.709-6.708v-5.75c0-4.792-1.917-6.709-6.709-6.709h-5.75c-4.791 0-6.708 1.917-6.708 6.709v5.75c0 4.791 1.917 6.708 6.708 6.708ZM15.094 8.625H7.906M15.094 14.375H7.906"
      stroke="#0B0F32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Invoice;
