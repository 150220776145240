import { useAuth } from 'hooks/useAuth';
import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { Role } from 'utils/enum';

export const RequireAuth = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export const RequireAccountAdmin = () => {
  const { user, isAuthenticated } = useAuth();
  if (!isAuthenticated || !user?.role) {
    return <Navigate to="/login" />;
  }
  if (user?.role !== Role.AccountAdmin) {
    return <Navigate to="/manage" />;
  }

  return <Outlet />;
};

export const RequireSuperAdmin = () => {
  const { user, isAuthenticated } = useAuth();
  if (!isAuthenticated || !user?.role) {
    return <Navigate to="/login" />;
  }
  if (user?.role !== Role.SuperAdmin) {
    return <Navigate to="/deployments" />;
  }

  return <Outlet />;
};
