import React from 'react';
import Check from './icons/CircleCheck';
import CircleCheck from './icons/CircleCheck';
import Javascript from './icons/Javascript';
import Java from './icons/Java';
import DotNet from './icons/DotNet';
import Php from './icons/Php';
import Python from './icons/Python';
import Ruby from './icons/Ruby';
import Go from './icons/Go';
import Google from './icons/Google';
import Aws from './icons/Aws';
import Azure from './icons/Azure';
import More from './icons/More';
import X from './icons/X';
import Exclamation from './icons/Exclamation';
import Setting from './icons/Setting';
import SignOut from './icons/SignOut';
import Search from './icons/Search';
import ArrowLeft from './icons/ArrowLeft';
import ArrowRight from './icons/ArrowRight';
import Credit from './icons/Credit';
import PasswordShow from './icons/PasswordShow';
import PasswordHide from './icons/PasswordHide';
import Invoice from './icons/Invoice';
import InvoiceInfo from './icons/InvoiceInfo';
import Filter from './icons/Filter';
import Link from './icons/Link';
import Cube from './icons/Cube';
import Reading from './icons/Reading';

type AppProps = {
  name: string;
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => any;
};

const iconMap: Record<string, any> = {
  check: Check,
  'circle-check': CircleCheck,
  javascript: Javascript,
  java: Java,
  dotNet: DotNet,
  php: Php,
  go: Go,
  python: Python,
  ruby: Ruby,
  google: Google,
  aws: Aws,
  azure: Azure,
  more: More,
  x: X,
  filter: Filter,
  link: Link,
  exclamation: Exclamation,
  setting: Setting,
  signOut: SignOut,
  search: Search,
  credit: Credit,
  invoice: Invoice,
  'invoice-info': InvoiceInfo,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'password-show': PasswordShow,
  'password-hide': PasswordHide,
  cube: Cube,
  reading: Reading,
};

const Icon: React.FC<AppProps> = ({ name, size, color, ...rest }) => {
  const Component = iconMap[name] || null;
  if (!Component) {
    return null;
  }
  return <Component name={name} size={size} color={color} {...rest} />;
};

export default Icon;
