import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const X: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#F8F6FE',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    viewBox={`0 0  ${size} ${size}`}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5305 3.53022C16.2376 3.23732 15.7628 3.23732 15.4699 3.53022L10.5001 8.50003L5.53033 3.53031C5.23744 3.23742 4.76256 3.23742 4.46967 3.53031C4.17678 3.8232 4.17678 4.29808 4.46967 4.59097L9.43939 9.56069L4.50971 14.4904C4.21682 14.7833 4.21682 15.2581 4.50971 15.551C4.8026 15.8439 5.27748 15.8439 5.57037 15.551L10.5001 10.6214L15.4298 15.5511C15.7227 15.844 16.1976 15.844 16.4905 15.5511C16.7834 15.2582 16.7834 14.7834 16.4905 14.4905L11.5607 9.56069L16.5305 4.59088C16.8234 4.29798 16.8234 3.82311 16.5305 3.53022Z"
      fill={color}
    />

  </svg>

);

export default X;
