import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Go: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 52,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size / 2 - 6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.918 4.849H4.535c-.1 0-.215.049-.264.124l8.647-.124Zm0 0-.29.446.29-.446Zm.425.264.074-.115c.049-.074.025-.15-.075-.15h-.393a.403.403 0 0 0 .028.248.41.41 0 0 0 .23.225l.135-.208Zm-.75.236-.016.024.128.083-.113-.107Zm.001 0h.097-.097Zm.156 0h.439-.439Zm14.648.586-.006.001-.047.013-.052.014a4.43 4.43 0 0 1-.11-.121c-.38-.444-.705-.777-1.313-1.069-1.74-.851-3.427-.597-4.953.405-1.826 1.19-2.768 2.96-2.742 5.138.018 2.224 1.548 4.08 3.753 4.392h.004c1.864.25 3.426-.425 4.638-1.8l.006-.008.007-.007c.181-.223.35-.466.526-.716l.162-.232.558-.789H22.07a.6.6 0 0 1 .02-.056c.382-.905.797-1.796 1.244-2.67l.005-.01.005-.01a.186.186 0 0 1 .067-.078.179.179 0 0 1 .097-.028h8.45l-.01.173c-.02.435-.04.844-.103 1.271v.001a10.133 10.133 0 0 1-1.933 4.636c-1.7 2.261-3.901 3.651-6.693 4.023-2.303.308-4.418-.143-6.27-1.539-1.712-1.303-2.682-3.021-2.939-5.171-.297-2.563.44-4.868 2.004-6.92C17.69 2.566 19.9 1.175 22.609.675 24.826.27 26.91.537 28.774 1.808l.005.003c1.157.77 2.003 1.812 2.584 3.074-.747.193-1.395.367-2.049.542-.607.163-1.219.326-1.916.508Zm4.107-.752v-.002.002ZM41.858 18.258c-.229.02-.466.042-.698.07-2.14-.058-4.057-.67-5.679-2.069l-.003-.002a7.314 7.314 0 0 1-2.492-4.483v-.004c-.421-2.654.297-5.002 1.9-7.124 1.724-2.274 3.776-3.45 6.599-3.952C43.91.263 46.132.51 48.149 1.885c1.84 1.264 2.965 2.952 3.258 5.18.402 3.2-.504 5.78-2.697 8.011l-.001.001c-1.55 1.585-3.453 2.585-5.663 3.031-.304.061-.615.097-.94.127l-.248.023Zm5.687-9.436.002-.034-.002-.034c-.006-.073-.01-.15-.015-.227a5 5 0 0 0-.067-.649c-.499-2.746-3.024-4.299-5.633-3.699-1.224.28-2.256.818-3.057 1.618-.801.8-1.348 1.838-1.639 3.082v.003c-.487 2.154.549 4.367 2.561 5.258 1.499.662 3.02.575 4.447-.163h.003c2.101-1.1 3.266-2.837 3.4-5.155Z"
      stroke={color}
    />
  </svg>
);

export default Go;
