import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Ruby: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 20,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M19.333 19.333V20H20v-.667h-.667Zm0-18.666H20V0h-.667v.667Zm-8 0V0h-.276l-.196.195.472.472ZM.667 11.333l-.472-.472-.195.196v.276h.667Zm0 8H0V20h.667v-.667ZM6 14l-.299.596.026.013.026.01L6 14Zm0-8v-.667A.667.667 0 0 0 5.333 6H6Zm8 0 .619-.248-.011-.025-.012-.026L14 6Zm6 13.333V.667h-1.333v18.666H20ZM19.333 0h-8v1.333h8V0Zm-8.472.195L.195 10.86l.944.944L11.805 1.14l-.944-.944ZM0 11.333v8h1.333v-8H0ZM.667 20h18.666v-1.333H.667V20ZM18.86.195.195 18.86l.944.944L19.805 1.14l-.944-.944ZM6.667 14V6H5.333v8h1.334ZM6 6.667h8V5.333H6v1.334Zm-.248 7.952 13.333 5.333.496-1.237L6.248 13.38l-.496 1.238Zm7.63-8.371 5.333 13.333 1.237-.496-5.333-13.333-1.238.496ZM10.736.965 13.404 6.3l1.192-.598L11.929.368l-1.192.597ZM.368 11.93l5.333 2.667.598-1.192-5.334-2.667-.597 1.192Z"
      fill={color}
    />
  </svg>
);

export default Ruby;
