import React from 'react';
import clx from 'classnames';

export enum Variants {
  Primary = 'primary',
  Negative = 'negative',
  PrimaryOutlined = 'primary-outlined',
  Outlined = 'outlined',
}

export enum Size {
  Default = 'default',
  Small = 'small',
}

type AppProps = {
  label: string | React.ReactNode;
  variant?: Variants;
  size?: Size;
  className?: string;
  dataTestId?: string;
  onClick?: (...args: any) => any;
  disabled?: boolean;
  loading?: boolean;
};

const Button: React.FC<AppProps> = ({
  label,
  variant = Variants.Primary,
  size = Size.Default,
  className = '',
  dataTestId = '',
  onClick = () => null,
  loading = false,
  disabled = false,
}) => {
  const _disabled = disabled || loading;

  const btnStyle = clx(
    `inline-flex items-center justify-center rounded-lg cursor-pointer ${className}`,
    {
      'bg-background-light border border-primary-light rounded-md':
        variant === Variants.PrimaryOutlined,
      'bg-white border border-primary-light rounede-md':
        variant === Variants.Outlined,
      'bg-secondary': variant === Variants.Primary,
      'bg-white border border-secondary': variant === Variants.Negative,
      'h-10': size === Size.Small,
      'bg-opacity-40 pointer-events-none': _disabled,
      'w-full': !className || !className.includes('w-'),
    },
  );

  const textStyle = clx('text-base font-bold py-3', {
    'text-primary':
      variant === Variants.PrimaryOutlined || variant === Variants.Outlined,
    'text-white': variant === Variants.Primary,
    'text-sm': size === Size.Small,
  });

  const btnContent = () => <div className={textStyle}>{label}</div>;

  return (
    <button
      data-testid={dataTestId}
      className={btnStyle}
      onClick={_disabled ? () => null : onClick}
    >
      {btnContent()}
    </button>
  );
};

export default Button;
