import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Javascript: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 22,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size + 2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M13.89 15.364a4.737 4.737 0 0 1-2.55-.465 1.677 1.677 0 0 1-.626-1.178.258.258 0 0 0-.264-.257c-.37-.004-.739-.004-1.108 0a.246.246 0 0 0-.27.217 2.729 2.729 0 0 0 .879 2.152 4.656 4.656 0 0 0 2.6.978 9.406 9.406 0 0 0 2.954-.126 3.647 3.647 0 0 0 1.958-1.054 2.727 2.727 0 0 0 .462-2.603 2.179 2.179 0 0 0-1.435-1.278c-1.493-.525-3.108-.484-4.632-.883-.265-.082-.588-.173-.706-.453a.998.998 0 0 1 .332-1.114 2.984 2.984 0 0 1 1.575-.392c.747-.07 1.5.038 2.197.315a1.675 1.675 0 0 1 .801 1.158.284.284 0 0 0 .266.275c.366.007.733.001 1.1.002a.266.266 0 0 0 .288-.196 2.84 2.84 0 0 0-1.384-2.457 6.86 6.86 0 0 0-3.755-.575 4.09 4.09 0 0 0-2.538 1.021 2.537 2.537 0 0 0-.507 2.639 2.25 2.25 0 0 0 1.421 1.239c1.49.538 3.122.365 4.625.841.294.1.635.252.724.578a1.154 1.154 0 0 1-.315 1.103 3.465 3.465 0 0 1-2.091.512Zm6.79-9.853L11.953.581a1.956 1.956 0 0 0-1.91 0l-8.693 4.91a1.799 1.799 0 0 0-.939 1.566v9.887a1.812 1.812 0 0 0 .975 1.581c.832.453 1.64.952 2.489 1.376a3.575 3.575 0 0 0 3.194.087 2.482 2.482 0 0 0 1.161-2.241c.006-3.263 0-6.526.002-9.788a.257.257 0 0 0-.241-.298c-.37-.004-.741-.004-1.112 0a.245.245 0 0 0-.266.249c-.005 3.242.001 6.484-.002 9.727a1.097 1.097 0 0 1-.712 1.03 1.786 1.786 0 0 1-1.447-.193l-2.312-1.307a.276.276 0 0 1-.157-.274V7.108a.302.302 0 0 1 .183-.303c2.888-1.627 5.775-3.256 8.662-4.885a.3.3 0 0 1 .34 0l8.665 4.884a.306.306 0 0 1 .182.303v9.786a.282.282 0 0 1-.157.278c-2.843 1.608-5.689 3.214-8.536 4.818-.136.075-.297.197-.455.105-.747-.422-1.482-.861-2.226-1.287a.24.24 0 0 0-.269-.016 6.09 6.09 0 0 1-1.029.48c-.16.066-.359.084-.47.234.142.154.313.277.504.361l2.609 1.509a1.902 1.902 0 0 0 1.93.054c2.899-1.634 5.797-3.268 8.695-4.905a1.815 1.815 0 0 0 .975-1.58V7.057a1.796 1.796 0 0 0-.908-1.546Z"
      fill={color}
    />
  </svg>
);

export default Javascript;
