import React from 'react';

const PageNotFound = () => {
  return (
    <div className="flex justify-center mt-6" data-testid="page-not-found">
      <div>
        <img src={require('images/empty-search-blue.png')} alt="" />
        <div
          className="font-bold text-center text-5xl mt-10"
          data-testid="page-not-found-title"
        >
          Page Not Found
        </div>
        <p
          className="text-text-light text-center text-lg mt-2"
          data-testid="page-not-found-info"
        >
          The page you’re looking for does not exist. It might have
          <br />
          been removed or renamed.
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
