import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const CircleCheck: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 16,
  color = '#28C955',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0  ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <circle opacity={0.2} cx={8} cy={8} r={8} fill={color} />
    <path
      d="m5.084 8.699 1.96 2.051 4.707-5.333"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheck;
