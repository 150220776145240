import * as React from "react";
import { SVGProps } from "react";

type AppProps = {
  size?: number;
  color?: string;
};

const Link: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 22,
  color = '#000',
  ...rest
}) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="m8 6 5-5M9.667 1H13v3.333m0 4v3.334A1.334 1.334 0 0 1 11.667 13H2.333A1.334 1.334 0 0 1 1 11.667V2.333A1.333 1.333 0 0 1 2.333 1h3.334"
      stroke="#000"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Link;

