import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Java: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 36,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.055 26.265c-4.635 1.29 2.805 3.96 8.7 1.5a10.772 10.772 0 0 1-1.65-.81c-2.047.466-4.161.558-6.24.27-1.965-.225-.81-.96-.81-.96Zm7.995-2.52c-2.64.575-5.357.706-8.04.39-1.965-.21-.675-1.155-.675-1.155-5.1 1.68 2.835 3.6 9.96 1.5a3.376 3.376 0 0 1-1.245-.735ZM24.78 9.15s-10.305 2.565-5.385 8.235a2.204 2.204 0 0 1-.375 3.18s3.69-1.905 1.995-4.29c-1.695-2.385-2.79-3.33 3.765-7.125Z"
      fill={color}
    />
    <path
      d="M27.195 28.71s.84.69-.945 1.23c-3.405 1.035-14.145 1.35-17.13 0-1.08-.465.93-1.11 1.5-1.245a3.81 3.81 0 0 1 1.035-.12c-1.2-.84-7.725 1.65-3.315 2.37C20.415 33 30.3 30.105 27.195 28.71Zm-4.14-7.32c.44-.279.902-.525 1.38-.735 0 0-2.265.405-4.5.6a42.168 42.168 0 0 1-7.23.075c-3.57-.465 1.905-1.83 1.905-1.83a10.215 10.215 0 0 0-4.74 1.125c-3.09 1.545 7.635 2.22 13.185.765Zm1.365 3.66a.587.587 0 0 1-.12.135c7.5-1.98 4.77-6.975 1.17-5.715a1.064 1.064 0 0 0-.495.375s.21-.075.645-.18c1.83-.375 4.38 2.445-1.2 5.385ZM18 19.5c-1.11-2.505-4.89-4.71 0-8.565C24.135 6.12 21 3 21 3c1.26 4.965-4.5 6.465-6.51 9.555-1.395 2.115.705 4.38 3.51 6.945Z"
      fill={color}
    />
    <path
      d="M24.615 31.98a44.624 44.624 0 0 1-13.71.21s.69.57 4.26.795c5.415.345 13.725-.18 13.935-2.745 0 0-.39.96-4.485 1.74Z"
      fill={color}
    />
  </svg>
);

export default Java;
