import React, { createContext, useEffect, useState } from 'react';
import { getCurrentUser } from '../queries/auth';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from 'utils/apiService';
import { isEmpty } from 'lodash';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Loader from 'components/Loader';

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  profileImage?: string;
  role: string;
  account: {
    id: string;
    name: string;
    domain: string;
  };
  deploymentsCount?: number;
  provisionType?: string;
  auth0Id?: string;
}

export const AuthContext = createContext({
  isAuthenticated: false,
  user: null as null | IUser,
  deploymentCreated: false,
  setDeploymentCreated: (...arg: any) => {},
  setUser: (...args: any) => {},
  logout: () => {},
});

type AppProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AppProps> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null);
  const [deploymentCreated, setDeploymentCreated] = useState(false);
  const [searchParams] = useSearchParams();

  const authError = searchParams.get('error_description') || null;

  const {
    isAuthenticated,
    isLoading,
    user: auth0User,
    getAccessTokenSilently,
    logout: auth0Logout,
  } = useAuth0();

  const logout = () => {
    setDeploymentCreated(false);
    queryClient.invalidateQueries();
    auth0Logout({ returnTo: window.location.origin });
    setUser(null);
  };

  useEffect(() => {
    if (isAuthenticated) {
      apiService.setTokenGenerator(getAccessTokenSilently);
    } else {
      apiService.setTokenGenerator(() => null);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const fetchCurrentUser = async () => {
    setLoading(true);
    if (isAuthenticated) {
      if (auth0User && !auth0User?.email_verified) {
        setLoading(false);
        return navigate({
          pathname: 'login-required',
          search: createSearchParams({
            error: authError || '',
          }).toString(),
        });
      }

      const d = await getCurrentUser();
      if (isEmpty(d)) {
        setLoading(false);
        return navigate('/onboarding');
      }
      if (!d?.account?.domain) {
        setUser(d);
        setLoading(false);
        return navigate('/project-info');
      }
      setUser(d);
      if (d.deploymentsCount > 0) {
        setDeploymentCreated(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      fetchCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  // if (error) {
  //   return <LoginError error={error} />;
  // }

  if (loading || isLoading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        deploymentCreated,
        setDeploymentCreated,
        setUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
