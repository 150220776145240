export enum FieldType {
  Input = 'input',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Checkbox = 'checkbox',
  ChipSingleSelect = 'chipSingleSelect',
  ChipMultiSelect = 'chipMultiSelect',
  Group = 'group',
}

export enum DeploymentStatus {
  Active = 'active',
  Pending = 'pending',
  InProgress = 'inprogress',
  ServiceInProgress = 'serviceinprogress',
  InActive = 'inactive',
  Terminated = 'terminated',
  Expired = 'expired',
  Error = 'error',
}

export enum Role {
  AccountAdmin = 'ACCOUNT_ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
}
