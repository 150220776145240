import * as React from 'react';
import { SVGProps } from 'react';

type AppProps = {
  size?: number;
  color?: string;
};

const Credit: React.FC<AppProps & SVGProps<SVGSVGElement>> = ({
  size = 23,
  color = '#000',
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.917 8.15h19.167M5.75 15.817h1.917M10.063 15.817h3.833M6.172 3.359h10.647c3.412 0 4.265.843 4.265 4.207v7.868c0 3.364-.853 4.207-4.255 4.207H6.172c-3.402.01-4.255-.834-4.255-4.197V7.566c0-3.364.853-4.207 4.255-4.207Z"
      stroke="#0B0F32"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Credit;
